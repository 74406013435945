import React from 'react';
import Link from 'next/link';
import { Typography } from '@mui/material';
import { PrimaryButton } from '@/components/common';
import { useTranslation } from '@/hooks/translations';

export default function NotFound() {
    const { t } = useTranslation('common');
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img src="/coming_soon.png" alt="coming soon" width="430" height="300" />
            <Typography
                variant="h6"
                color="primary"
                sx={{
                    marginTop: '1rem',
                }}
            >
                {t('404 Not Found')}
            </Typography>
            <Typography variant="h3" color="primary">
                {t(`This page can't be found`)}
            </Typography>
            <Link href="/">
                <PrimaryButton
                    text={t('back to homepage')}
                    sx={{
                        marginTop: '1rem',
                    }}
                />
            </Link>
        </div>
    );
}
